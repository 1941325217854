import { useTranslation } from 'react-i18next';
import client, { ClientProps } from './client';
import user, { UserProps } from './user';

const useSchemas = (): { client: ClientProps; user: UserProps } => {
  const { t } = useTranslation('schemas');

  return {
    client: client(t),
    user: user(t),
  };
};

export default useSchemas;
